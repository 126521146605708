/* Colours */

$white: #FFFFFF;
$white-d: #f4f4f4;
$whiteD: #f4f4f4;
$black: #2e3133;
$blackL: #474a4b;
$blackD: #100f12;
$blackF: #000000;
$gray: #adabac;
$grayD: #7b7b7b;
$grayL: #dfddde;
$border-white: rgba(256,256,256,0.10);
$border-black: rgba(0,0,0,0.15);
$border-light: #e9ecef;
$transparent: rgba(0,0,0,0.0);



$white-o1: rgba(255,255,255,0.88);
$white-o2: rgba(255,255,255,0.79);
$white-o3: rgba(255,255,255,0.71);
$white-o4: rgba(255,255,255,0.62);
$white-o5: rgba(255,255,255,0.51);
$white-o6: rgba(255,255,255,0.38);
$white-o7: rgba(255,255,255,0.23);
$white-o8: rgba(255,255,255,0.15);
$white-o9: rgba(255,255,255,0.1);

$black-o1: rgba(0,0,0,0.8);
$black-o2: rgba(0,0,0,0.7);
$black-o3: rgba(0,0,0,0.6);
$black-o4: rgba(0,0,0,0.5);
$black-o5: rgba(0,0,0,0.4);
$black-o6: rgba(0,0,0,0.3);
$black-o7: rgba(0,0,0,0.2);
$black-o8: rgba(0,0,0,0.1);
$black-o9: rgba(0,0,0,0.07);

$torq: #0097a7;
$torq: #00bcd4;

$primary: $black;
$primaryL: $blackL;
$primaryD: $blackL;
$secondary: $white;
$secondaryL: $white;
$secondaryD: $white;
$variant: $gray;
$variantL: $grayL;
$variantD: $grayD;
$trim: $torq;




$orange: #c0794b;
$blue: #5f9be4;
$blueD: #206db2;
$blueDD: #004382;
$violet: #a86588;
$violetD: #78395b;
$violetDD: #4a0d32;
$violetL: #db94b8;
$yellow: #edd781;
$yellowL: #ffffb2;

$primary: $orange;
$secondary: $blue;
$secondaryD: $blueD;
$secondaryDD: $blueDD;
$variant: $violet;
$variantD: $violetD;
$variantDD: $violetDD;
$variantL: $violetL;
$trim: $yellow;
$trimL: $yellowL;



/* NEW */

$redM: #e36235;
$redML: #f28058;
$blueM: #5ab2c9;
$blueMD: #4090ad;
$blueMDD: #437dac;
$greenM: #77c7aa;
$greenMD: #5fbea1;
$greenMDD: #559d87;
$greenML: #90e8c8;
$yellowM: #ffe455;
$yellowML: #feed89;

$primary: $redM;
$secondary: $blueM;
$secondaryD: $blueMD;
$secondaryDD: $blueMDD;
$variant: $greenM;
$variantD: $greenMD;
$variantDD: $greenMDD;
$variantL: $greenML;
$trim: $yellowM;
$trimL: $yellowML;

/* POST-EDDIE */

$black-main: #252424;
$black-lighter: #454a58;
$black-darker: #000000;

// $white-main: #e9eaf0;
// $white-lighter: $white;
// $white-darker: #cfd1da;
// $white-darkest: #b0b2be;

$white-main: #f9f9f9;
$white-lighter: $white;
$white-darker: #e4e8ec;
$white-darkest: #bec2c9;

$white: $white-main;
$white-d: $white-darker;
$whiteD: $white-darker;

$black: $black-main;
$blackL: $black-lighter;
$blackD: $black-darker;


$primary: $black-main;
$secondary: $black-main;
$secondaryD: $black-darker;
$secondaryDD: $blackF;

$variant: $white-main;
$variantD: $white-darker;
$variantDD: $white-darkest;
$variantL: $white-lighter;

$trim: $black-main;
$trimL: $black-lighter;
