/**
 * @section Base Styles
 */

 html {
     min-height: 100%;
     height: 100%;
     margin: 0;
 }

 body {
     min-height: 100%;
     max-width: 100%;
     height: 100%;
     scroll-behavior: smooth;
     margin: 0;
     font-size: 100%;
     font-family: $main-font;
     background: #FFFFFF;
     -webkit-font-smoothing: antialiased;
     font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     scrollbar-width: 0px;
 }

 h1 {
     font-family: $title-font;
 }

 h2,h3,h4,h5,h6 {
     font-family: $main-font;
 }


 p {
     line-height: 1.45;
     font-size: 1.15rem;
     font-weight: 400;
     margin: 10px auto 22.5px auto;
     text-align: left;
     color: $black;
     text-rendering:  optimizeLegibility;
     font-family: $main-font;
     letter-spacing: 0.24px;

     strong {
         font-weight: 800;
         font-weight: bold;
     }

     em {
         font-style: italic;
     }
 }

 img {
     -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }

 section {
     margin: 0!important;
 }


 ::-webkit-scrollbar {
     width: 0px;
     background: transparent;
 }

 *::selection {
     background: $black;
     color: $white;
     -webkit-text-stroke-color: $white;
 }

 *::-moz-selection {
     background: $black;
     color: $white;
     -webkit-text-stroke-color: $white;
 }

 a,button,input[type=button],input[type=submit],.btn,.btn-primary {
     font-family: $nav-font;
     @include transition(all 250ms ease-in-out);

     &:active,
     &:focus {
         outline: none;
         box-shadow: none;
         -webkit-box-shadow: none;
         -moz-box-shadow: none;
     }
 }

 .btn.btn-primary {
    line-height: 1.5;
    font-size: .95rem;
    border: 1px solid $black-o4;
    padding: 8px 12.5px;
    color: #fff!important;
    cursor: pointer!important;
    box-shadow: 2px 2px 0 2px $variantD;
    display: inline-block;
    font-weight: 700!important;
    background: $variant!important;
    -webkit-text-stroke: 0.5px $black-o1;
    letter-spacing: 2.15px!important;
    text-indent: 1.5px;
    text-transform: uppercase;

	background: $secondary!important;
	color: $white;
	-webkit-text-stroke: none;
	box-shadow: 2px 2px 0 2px $secondaryD;

    &:hover {
        // box-shadow: 2px 2px 0 2px $variantDD;
        // background: $variantD!important;

		box-shadow: 2px 2px 0 2px $secondaryDD;
        background: $secondaryD!important;
    }

    &.btn-alt {
        background: $secondary!important;
        box-shadow: 2px 2px 0 2px $secondaryD;

        &:hover {
            box-shadow: 2px 2px 0 2px $secondaryDD;
            background: $secondaryD!important;
        }
    }

    @include for-phone-only {
        font-size: 1.25rem;
        -webkit-text-stroke: 0.68px $black-o1;
    }
 }

 .master {
     width: 100%;
     height: auto;
     min-height: 100%;
     text-align: center;
     position: relative;
     margin: 0;
     background: #FFFFFF;
     display: block;
     font-family: $main-font;
     background: none;
     overflow-x: hidden;
 }


 .wrap {
     @include align-middle;
     position: relative;
     text-align: center;
     clear: both;
     z-index: 100;
 }

 /* Full Panel */

 .window {
     width: 100%;
     height: 100vh;
     min-height: 100%;
     position: relative;
     z-index: 50;
     display: block;
     background: #FFFFFF;
     @include box-sizing(border-box);

     > .wrapper {
         display: table;
         height: 100%;
     }

     > .container,
     > .container-fluid {
         display: table;
         height: 100%;
     }

 }

 /* Center Img Crop */

 .crop {
     overflow: hidden;
     position: relative;
     display: inline-block;

     img {
       @include img-crop;
     }

     &.user {
         @include border-radius(50%);
     }
 }

 .img-wrap {
     @include abs-pos(0,0,0,0);
     width: 100%;
     height: 100%;
     margin: 0;
     display: block;
     z-index: 1;
     overflow: hidden;

     img {
         @include img-wrap;
     }
 }


 /* Tint Overlay */

 .overlay-tint {
     z-index: 75;
     position: absolute;
     display: block;
     width: 100%;
     height: 100%;
 }

 /* Link Vanish */

 a.vanish {
     position: absolute;
     left: 0; right: 0; top: 0; bottom: 0;
     opacity: 0;
     visibility: hidden;
     z-index: 9999;
 }

 a.mask {
     @include abs-pos(0,0,0,0);
     display: block;
     width: 100%;
     height: 100%;
     background: $transparent;
     z-index: 500;
 }

 a.mask-self {
     @include abs-pos(0,0,0,0);
     display: block;
     width: 100%;
     height: 100%;
     background: $transparent;
     z-index: 9999;
 }

 /* Cinemagraph */

 .cinemagraph {
     position:relative;
     height:0;
     padding-bottom:56.25%;
     width:100%;

     iframe {
         position:absolute;
         top: 0;
         left:0;
         width:100%;
         height:100%;
     }
 }

 .social-menu {

     a.social-link {
         color: $white;

         &#IG:hover { color: #5851db; }
         &#FB:hover { color: #3b5998; }
         &#TW:hover { color: #1da1f2; }
         &#YT:hover { color: #ff0000; }
         &#SP:hover { color: #1db954; }
         &#AM:hover { color: #A3AAAE; }
         &#AZ:hover { color: #f2941b; }
     }
 }


 .no-anim {
     animation-delay: 0s !important;
     -webkit-animation-delay: 0s !important;
 }


 .left-col,
 .right-col {
     text-align: left!important;

     > [class^="col-"] * {
         margin-left: auto;
         margin-right: auto;
     }
 }

 .left-col {

     div {
         margin-right: auto;
         margin-left: 0;
         display: block;
     }

 }

 .right-col {

     div {
         margin-left: auto;
         margin-right: 0;
         display: block;
     }

 }

 .order-last {
     @include for-breakpoint {
         order: -1!important;
     }
 }

 .order-last-lg {
     @include for-breakpoint-lg {
         order: -1!important;
     }
 }


 .hidden {
   overflow: hidden;
   display: none;
   visibility: hidden;
 }

 .invert {
   @include filter(invert,100%);
 }


 .social-icon {
     &.fbH:hover {
         color: #3b5998!important;
     }

     &.twH:hover {
         color: #1da1f2!important;
     }

     &.igH:hover {
         color: #c13584!important;
     }

     &.gpH:hover {
         color: #dd4b39!important;
     }

 }


 .video-container {
 position: relative;
 padding-bottom: 56.25%;
 padding-top: 25px; height: 0;
 -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
 box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
 }

 .video-container iframe,
 .video-container object,
 .video-container embed {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 }
