
/* Fonts (& font-face imports) */

$nav-font: 'Alte Haas Grotesk', sans-serif;
$brand-font: 'Alte Haas Grotesk', sans-serif;
$main-font: 'Alte Haas Grotesk', sans-serif;
$title-font: 'Alte Haas Grotesk', serif;
$read-font: $main-font;

// $read-font: 'Alternate Gothic No3 D', 'Alte Haas Grotesk', sans-serif;
// $main-font: 'Alternate Gothic No3 D', 'Alte Haas Grotesk', sans-serif;
// $brand-font: 'Alternate Gothic No3 D', 'Alte Haas Grotesk', sans-serif;
// $nav-font: 'Alternate Gothic No3 D', 'Alte Haas Grotesk', sans-serif;

$header-font:'Alternate Gothic No3 D', 'Alte Haas Grotesk', sans-serif;

$header-font: 'Calbran Compressed', 'Alte Haas Grotesk', sans-serif;


@font-face {
    font-family: 'Alte Haas Grotesk';
    src: url('../fonts/AlteHaasGrotesk_Bold.eot');
    src: url('../fonts/AlteHaasGrotesk_Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AlteHaasGrotesk_Bold.woff2') format('woff2'),
        url('../fonts/AlteHaasGrotesk_Bold.woff') format('woff'),
        url('../fonts/AlteHaasGrotesk_Bold.ttf') format('truetype'),
        url('../fonts/AlteHaasGrotesk_Bold.svg#AlteHaasGrotesk_Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Alte Haas Grotesk';
    src: url('../fonts/AlteHaasGrotesk.eot');
    src: url('../fonts/AlteHaasGrotesk.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AlteHaasGrotesk.woff2') format('woff2'),
        url('../fonts/AlteHaasGrotesk.woff') format('woff'),
        url('../fonts/AlteHaasGrotesk.ttf') format('truetype'),
        url('../fonts/AlteHaasGrotesk.svg#AlteHaasGrotesk') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Alte Haas Grotesk';
    src: url('../fonts/AlteHaasGrotesk_Bold_1.eot');
    src: url('../fonts/AlteHaasGrotesk_Bold_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AlteHaasGrotesk_Bold_1.woff2') format('woff2'),
        url('../fonts/AlteHaasGrotesk_Bold_1.woff') format('woff'),
        url('../fonts/AlteHaasGrotesk_Bold_1.ttf') format('truetype'),
        url('../fonts/AlteHaasGrotesk_Bold_1.svg#AlteHaasGrotesk_Bold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Alternate Gothic No3 D';
    src: url('../fonts/AlternateGotNo3D.woff2') format('woff2'),
        url('../fonts/AlternateGotNo3D.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Calbran Compressed';
    src: url('../fonts/CalbranCompressedSemiBold.woff2') format('woff2'),
        url('../fonts/CalbranCompressedSemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calbran Compressed';
    src: url('../fonts/CalbranCompressedBold.woff2') format('woff2'),
        url('../fonts/CalbranCompressedBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

