@import 'utils/variables';
@import 'utils/mixins';
@import "components/type";
@import "components/base";
@import 'vendors/animate';
@import 'vendors/magnific-popup';
@import 'vendors/hamburgers';
@import 'vendors/splitchar';
@import 'vendors/pagepiling';



:root {
	// --color-orange: #fea702;
    --color-orange: #fcb42b;
    --color-orange: #ffa651;
    // --color-blue: #009fe0;
    --color-blue: hsl(197, 100%, 50%);
    --color-blue: hsl(203, 85%, 74%);
    // --color-red: #fb4659;
    --color-red: hsl(353, 97%, 67%);
    --color-red: hsl(16, 80%, 54%);
    --color-red: hsl(16, 81%, 47%);

	--color-primary: var(--color-orange);
    --color-accent-1: var(--color-blue);
    --color-accent-2: var(--color-red);

	--color-bg: var(--color-primary);

    --color-bg: var(--color-accent-2);

    --color-dark: #252424;
    --color-light: #eee;
    --text: var(--color-dark);
    --dark: 37, 36, 36;
    --light: 255,255,255;
    --mode: var(--dark);

    --text: var(--color-dark);
    --mode: var(--dark);

    --color-text-o1: rgba(var(--mode), 0.8);
    --color-text-o2: rgba(var(--mode), 0.7);
    --color-text-o3: rgba(var(--mode), 0.6);
    --color-text-o4: rgba(var(--mode), 0.5);
    --color-text-o5: rgba(var(--mode), 0.4);
    --color-text-o6: rgba(var(--mode), 0.3);
    --color-text-o7: rgba(var(--mode), 0.2);
    --color-text-o8: rgba(var(--mode), 0.15);

    scrollbar-width: none!important;

}

@import 'layout/navbar';
@import 'layout/footer';




body {
    font-family: $main-font;
    overflow-x: hidden!important;
    // overflow-y: hidden!important;
    background: $white;
	background: var(--color-bg);
    text-transform: uppercase!important;
    // font: 110%/1.2em $main-font;

    
}

#bustified {
    transition: all 450ms ease-in-out;

    
}

.fullWrapper {
    padding-right: 2em;

    margin-top: 30px;
    margin-bottom: 30px;
    

    @include for-breakpoint-lg {
        margin-bottom: 1.5em;
        max-width: 80%;
    }

    @include for-break-point {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 3em;
        margin-left: auto;
        margin-right: auto;
    }

    @include for-phone-only {
        max-width: 100%;
    }

    @include for-breakpoint-lg-up {
        max-width: 100%;
    }
}
.video-wrapper {
    position: relative;
    height: 0;
    padding-top: 100%;
    overflow: hidden;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.22);

    > * {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

h1 {
    font-size: 4em;
    text-transform: uppercase;

    &.caption-sm {
        font-size: 2.25em;
        line-height: 1.0;
    }

    strong {
        font-weight: 700;
    }
}

h2 {
    font-size: 2.5em;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.15px;

    @include for-breakpoint-md {
        font-size: 1.75em;
    }
}

h3 {
    font-size: 1.15em;
    text-transform: uppercase;
    letter-spacing: 0.95px;
    font-weight: 700;
}

h5 {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: 0.25px;
}

.overlay-full {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;

    &.overlay-hue {
        background-repeat: repeat;
        background-size: 8%;
        background-position: center;
        background-attachment: local;
    }

    &.overlay-max {
        background: $black-o1;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: all 500ms ease-in-out;
        position: fixed;

        &.max-active {
            z-index: 5000;
            visibility: visible;
            opacity: 1;
        }

        &.max-almost {
            z-index: 10;
            visibility: visible;
            opacity: 1;
        }
    }
}

.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;

  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
  width: 108%;
  height: auto;
}

.container-flush {
    padding-left: 0;
    padding-right: 0;
}

.no-mask {
    -webkit-mask-image: none!important;
  mask-image: none!important;
  transition-delay: 1s!important;
}

.pp-scrollable {
    scrollbar-width: none!important;

    &::-webkit-scrollbar {
        display: none!important;
    }
}

.section {
    padding-top: 63px;
    padding-bottom: 45px;
    /* social icons */
    padding-bottom: 0;

    // min-height: 100vh!important;
    scrollbar-width: 0px;
    overflow-x: hidden;
    background-color: var(--color-bg)!important;
    background-blend-mode: multiply!important;

    // scrollbar-width: none!important;

    // &::-webkit-scrollbar {
    //     display: none;
    // }


    // position: relative;

    @supports (background-blend-mode: multiply) {
        background-image: url('../imgs/textures/subtle-grunge.png')!important;
        background-blend-mode: overlay!important;
        background-size: 28%!important;
        background-attachment: scroll!important;
    }

    // &:before {
    //     content: "";
    //     height: 100%;
    //     width: 100%;
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     background-image: url('../imgs/textures/batb_overlay_grain.jpg');

    //     @supports (background-blend-mode: multiply) {

    //     }
    // }


    &.overlay-active {

        @include for-phone-only {
            background: $black!important;
        }
    }

    @include for-breakpoint-md {
        padding-top: 50px;
    }

    .gradient-bar {
        display: none;

        &.flipped {
            left: auto!important;
            right: 0!important;
            width: 70px;
            // border-right: 6px solid $trim;
            background: linear-gradient(0deg, rgba(237,215,129,1) 0%, rgba(168,101,136,1) 50%, rgba(95,155,228,1) 100%);
            background: linear-gradient(0deg, rgba(168,101,136,1) 0%, rgba(95,155,228,1) 100%);
            background-color: $trim;
            box-shadow: -1px 0px 15px 0px rgba(0,0,0,0.25);
            background-image: url('../imgs/banners/side-blur_4.jpg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-attachment: local;
            background-position: center;

            background-image: url('../imgs/banners/sleeve-texture-2_bw.jpg');
            // background-repeat: repeat-y;
            background-size: auto 100%!important;
            background-blend-mode: multiply;
            box-shadow: -1px 0px 22px 0px rgba(0,0,0,0.15);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: auto;
                // margin-right: 0;
                // right: 5px;
                position: relative;
            }
        }

        @include for-break-point {
            display: none!important;
        }
    }

    @include for-breakpoint-md-up {
        .gradient-bar {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            display: block;
            height: 100vh;
            width: 30px;
            z-index: 50;

            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    //  .container-xl,
    //  .container-lg,
    //  .container-md,
    //  .container-sm,
    //  .container,
    //  .container-flush,
     .container-fluid {
         overflow-y: scroll;
         box-sizing: border-box;
         padding-bottom: 0;
        height: 100%;
        z-index: 100;
        position: relative;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 55px;
        transition: all 300ms ease-in-out;

        scrollbar-width: none!important;

        &::-webkit-scrollbar {
            display: none;
        }

        @include for-breakpoint-md {
            padding-bottom: 45px;
        }
    }

    &.home  {
        background-image: url('../imgs/banners/bb_bg2_XXL_final.jpg');
        background-image: url('../imgs/banners/eddie-background-banner.jpg');
        background-image: url('../imgs/banners/eddie-red-banner.jpg');
        background-size: cover;
        background-position: right 25px top;
        background-position: right 45px top;
        background-repeat: no-repeat;
        background-size: 108%;
        background-attachment: fixed;
        // background-color: $primary;
        background-color: $white-main;
        background-blend-mode: luminosity;
        background-image: url('../imgs/banners/film_grain_white_2.png');
        background-size: 88px;
        background-repeat: repeat;

        @include for-breakpoint-xl {
            // background-image: url('../imgs/banners/bb_bg2.jpg');
            // background-image: url('../imgs/banners/eddie-red-banner.jpg');
            // background-size: auto 100%!important;
            // background-position: right -45px top;
        }

        @include for-breakpoint-md {
            background-size: 40px;
        }

        .container-fluid {
            -webkit-mask-image: linear-gradient(to top, transparent 21px, black 21%);
          mask-image: linear-gradient(to top, transparent 21px, black 21%);
        }

        .overlay-hue {
            display: none;

            img.mobile-banner {
                @include img-crop;
                height: 100%;
                width: auto;


            }

            @include for-breakpoint-md {
                display: block;
                mask-image: linear-gradient(to bottom, transparent 7px, black 10%);
            }


        }

        .featured-col {
            text-align: center;
            margin-bottom: 0.8em;


            @include for-phone-only {
                margin-bottom: 1.5em;
                padding-bottom: 0!important;
                padding-top: 0px!important;
            }

            @include for-breakpoint-lg-up {
				padding-right: 3rem!important;
			}
        }


        div[class*="col-"] {
            padding-top: 15px;
            pading-bottom: 15px;

            @include for-break-point {
                padding: 45px 0;
            }
        }

        h1.caption-sm {
            font-size: 1.5em;
            line-height: 1.05;
            letter-spacing: 1.15px;
            // color: $white;
            // color: $black-main;
            color: var(--text);

            

            @include for-breakpoint-md {
                position: relative;
                z-index: 500;
            }

            @include for-breakpoint-xl-up {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }

        h1 strong {
            display: block;
            font-size: 2em;
            letter-spacing: 0.25px;
            margin-top: 2.5px;
            margin-bottom: 2.5px;
            color: $white;
            // text-shadow: 0px 0px 9px rgba(92,255,185,0.58);
            // text-shadow: 0px 0px 8px rgba(255,255,0,0.66);
            // text-shadow: 0px 0px 9px rgba(255, 255, 135, 0.66);

            

            @include for-breakpoint-md {
                font-size: 3em;
            }



            font-family: $header-font;
            // color: $black;
            color: var(--text);
            // letter-spacing: -0.25rem;
            letter-spacing: -0.0725px;
            font-weight: normal;
            font-weight: 600;

            // @supports (-webkit-text-stroke: 1px black) {
            //     -webkit-text-stroke: 1.5px $primary;
            //     -webkit-text-fill-color: transparent;
            // }
        }

        // Fluid is the dynamic text part...
        h1 span:not(#fluid) {
            font-weight: 700;

            @include for-breakpoint-md {
                display: block;
                font-size: 1.65em;
            }
        }

        img.featured-img {
            max-width: 90%;
            margin: 30px auto;
            box-shadow: -2px 2px 8px 0px rgba(0,0,0,0.2);

            // @include for-breakpoint-md {
            //     width: 70%;
            //     margin-top: 60vh;
            //     position: relative;
            //     z-index: 500;
            // }

            @include for-breakpoint-lg {
                max-width: 80%;
            }

            @include for-phone-only {
                max-width: 90%;
            }

			@include for-breakpoint-lg-up {
				max-width: 100%;
			}
        }

        .featured-col-2 {

            @include for-breakpoint-lg {
                text-align: center;
                padding-top: 0!important;
                padding-bottom: 1.5em;
                // margin-top: -22.5px!important;
            }

            @include for-phone-only {
                // margin-top: -22.5px!important;
            }

            // h1 > strong:first-child {
            //     margin-top: 0em;
            // }
            //
            // h1 > strong:last-child {
            //     margin-bottom: 0.15em;
            // }

            h1 strong {
                font-size: 6em;
                line-height: 92%;
                margin-top: 0.75rem;
                margin-bottom: 0.75rem;

                @include for-breakpoint-xl {
                    font-size: 4em;
                }

                @include for-breakpoint-lg {
                    font-size: 3em;
                }

                @include for-breakpoint-md {
                    font-size: 3.25em;
                }
            }
        }

        // #home-texture {
        //     background-image: url('../imgs/banners/film_grain_RED.png');
        //     background-repeat: repeat;
        //     background-size: cover;
        //     background-position: center;
        //     background-attachment: local;
        //     display: block;
        //     background-size: 100%;
        //     z-index: -1;
        //     opacity: 1;
        //
        //     + .container-fluid > .container-md {
        //         position: relative;
        //         z-index: 500;
        //     }
        // }


        .home-container {

            @include for-breakpoint-lg-up {
                max-width: 100%;
                padding-left: 30px + 50px;
                padding-right: 50px + 70px;
            }
            

            // @include for-breakpoint-xl-up {
            //     max-width: 100%;
            //     padding-left: 50px;
            //     padding-right: 50px;
            // }

        }

    }

    &.music {
        // background: $secondary;
        // background-color: $primary;
        // background-image: url('../imgs/banners/film_grain.png');
        background-color: $white-main;
        background-blend-mode: multiple;
        background-image: url('../imgs/banners/film_grain_white_2.png');
        background-size: 88px;
        background-repeat: repeat;
        background-color: var(--color-accent-1)!important;

        @include for-breakpoint-md {
            background-size: 40px;
        }


        .container-fluid {
            -webkit-mask-image: linear-gradient(to top, transparent 25px, black 25%);
          mask-image: linear-gradient(to top, transparent 25px, black 25%);
      }

      .container-xl {


            > .row:first-child {
                margin-top: 2em;

                @include for-breakpoint-md {
                    margin-top: 2.5em;
                }
            }

            > .row:last-child {
                margin-bottom: 2em;

                @include for-breakpoint-md {
                    margin-bottom: 3.5em;
                }
            }
        }

        .overlay-hue {
            opacity: 0.05;
            background-image: url('../imgs/banners/grain.png');
        }

        img.album-img,
        .album-link > img,
        img.album-img-mod,
        img.album-link {
            max-width: 90%;
            margin: 40px auto 15px auto;
            box-shadow: -2px 2px 8px 0px rgba(0,0,0,0.2);
            cursor: pointer;
            transition: all 500ms ease-in-out;
            transform: translateZ(0.0);
            display: block;

            &:hover {
                transform: scale(1.03);
            }

            &.overlay-active {
                transform: scale(1.03);
            }
        }

        h5 {
            margin: 0.5em auto;
            display: block;
            text-align: center;
            // color: $black;
            // color: $white;
            color: var(--text);

            sup {
                top: -0.15em;
                padding-left: 2.5px;
                // color: $trim;

                color: var(--color-text-o2);
                letter-spacing: 0.5px;
            }
        }


        > div > div > .row {


            > div[class="col-sm-5"]:first-child {

                @include for-breakpoint-lg-up {
                    // flex: 0 0 calc(41.666667% * 2);
                    // max-width: calc(41.666667% * 2);
                    flex: 0 0 80%;
                    max-width: 80%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    > img {
                        // max-width: 52%;
                        // flex: 0 0 52%;
                        max-width: 58%;
                        flex: 0 0 58%;
                        order: 2;

                    }

                    > h5 {
                        padding-right: 1.5rem;
                        order: 1;

                        // font-size: 4.5rem;
                        font-size: 3.5rem;
                        // font-family: $header-font;
                        text-transform: uppercase;
                        line-height: 0.9;
                        // word-spacing: 9999px;
                        font-style: italic;

                        sup {
                            display: block;
                            font-size: 20%;
                            font-family: $main-font;
                            word-spacing: normal;
                            line-height: 1.5;
                            margin-top: 1rem;
                            font-style: normal;
                        }
                    }
                }
            }
        }

    }

    #partner-wrapper {
        border-top: 1px solid $white-o9;

        @include for-phone-only {
            width: 100%;
            text-align: center;
        }
    }

    img.partner {
        width: 130px;
        height: auto;
        display: inline-block;
        vertical-align: middle;
        opacity: 0.8;

        &#CCA {
            width: 158px;
        }

        @include for-breakpoint-lg-up {
            width: 140px;

            &#CCA {
                width: 170px;
            }
        }

        @include for-breakpoint-md {
            width: 100px;
            margin: 0 10px!important;

            &#CCA {
                width: 150px;
            }
        }

        @include for-phone-only {
            width: 100px;
            margin: 0 5px!important;
        }

    }

    &.tour {
        // background: $variant;
        // background-color: $primary;
        // background-image: url('../imgs/banners/film_grain.png');
        background-color: $white-main;
        background-blend-mode: multiply;
        background-image: url('../imgs/banners/film_grain_white_2.png');
        background-size: 88px;
        background-repeat: repeat;
        background-color: var(--color-primary)!important;

        @include for-breakpoint-md {
            background-size: 40px;
        }

        .container-fluid {
            -webkit-mask-image: linear-gradient(to top, transparent 25px, black 25%);
          mask-image: linear-gradient(to top, transparent 25px, black 25%);
        }

        h2 {
            // color: $trim;
            color: var(--text);

            @include for-breakpoint-md {
                margin-top: 2em;
            }

            @include for-phone-only {
                margin-top: 0em;
            }
        }

        .tourbox-wrapper {
            padding-bottom: 2.5em;

            @include for-phone-only {
                padding: 0 1.7em;
                padding-bottom: 5.5em;
                margin-bottom: 0!important;
            }
        }

        #tourbox {
			color: $primary;

            // @include for-phone-only {
            //     width: 80%;
            //     padding-bottom: 2em;
            // }

            .bit-logo-desktop {
                display: none!important;
            }

            .bit-event {
                transition: all 300ms ease-in-out;
                padding-bottom: 22.5px;

                &:hover {
                    background: rgba(0,0,0,0.035);
                }

                &:first-of-type {
                    border-top: none;
                }
            }

            .bit-upcoming-events {
                border-bottom: none;
            }

            .bit-widget.bit-layout-desktop .bit-date {
                letter-spacing: 1px;
                font-size: 1.5em;
            }

            .bit-no-dates-title {
                color: $primary;

                color: var(--text);
            }

            .bit-top-track-button {
                display: none;
            }

            .bit-widget .bit-event-list-title {
                display: block;
                margin: 15px auto;
                font-size: 3em;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                text-align: center;
                width: 100%;
                display: none;
            }

            .bit-venue {
                // color: $black;
                letter-spacing: 0.5px;
                font-size: 1em;
                color: var(--text);
            }

            .bit-location {
                letter-spacing: 0.5px;
                font-size: 1em;
            }

            .bit-widget .bit-offers,
            .bit-play-my-city-button {
                color: $white;
                background: $secondary!important;
                box-shadow: 2px 2px 0 2px $secondaryD;
                border-radius: .25rem;
                letter-spacing: 2px;
                // -webkit-text-stroke: 0.25px $black-o1;

                -webkit-text-stroke: 0.25px var(--color-text-o1);
                // border: 1px solid $black-o4;

                // border: 1px solid var(--color-text);

                border: none;

                &:hover {
                    box-shadow: 2px 2px 0 2px $secondaryDD;
                    background: $secondaryD!important;
                }

                @include for-phone-only {
                    font-size: 1.25rem;
                    // -webkit-text-stroke: 0.68px $black-o1;
                    -webkit-text-stroke: 0.68px var(--color-text-o1);
                }
            }

            .bit-widget .bit-event .bit-rsvp {
                border-color: $trim;
                color: $trim;

                border-color: var(--text);
                color: var(--text);
                letter-spacing: 2px;

                @include for-phone-only {
                    font-size: 1.25rem;
                    margin-top: 5px;
                }
            }

        }
    }
}


.link-overlay {
    @extend .overlay-full;

    background: $black-o1;
    visibility: hidden;
    opacity: 0;
    left: -100%;
    transition: all 350ms ease-in;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;

    .links {
        transform: scale(0.15);
        transition: all 500ms ease-in;
        opacity: 0;
        visibility: hidden;
        text-align: center;

        img.preview-img {
            width: 225px;
            width: 275px;
            height: auto;
            display: block;
            margin: 25px auto 15px auto;

            // transform:scale(10.0);
            transform:scale(12.0);
            position: absolute;
            top: 0; right: 0;
            left: 0; bottom: 0;
            object-fit: cover;
            filter: saturate(200%) brightness(72%) blur(5px);
            z-index: -1;

            // @include for-breakpoint-md {
            //     width: 188px;
            //     margin-top: -20px;
            //     margin-bottom: 25px;
            // }
        }
    }

    &.overlay-active {
        left: 0;
        visibility: visible;
        opacity: 1;
        z-index: 9999;

        .links {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
        }
    }

    .links h3 {
        display: block;
        margin: 15px auto;
        color: $whiteD;
        line-height: 1.25;
        color: $white-main;
        -webkit-text-stroke: 0.5px var(--color-dark);
        padding-left: 1em;
        padding-right: 1em;

        // @include for-breakpoint-xxl-up {
        //     max-width: 92.5%;
        //     margin-left: auto;
        //     margin-right: auto;
            
        // }


        em {
            display: block;
            font-style: normal;
            // font-size: 1.5em;
            color: $white-main;

            @include for-breakpoint-xxl-up {
                margin-top: 1em;
            }

            // @supports (-webkit-text-stroke: 1px black) {
            //     -webkit-text-stroke: 0.85px $variant;
            //     -webkit-text-fill-color: transparent;
            // }

            span {
                font-family: $header-font;
                font-size: 2.5em;
                font-weight: normal!important;
                letter-spacing: 0.005rem;
                position: relative;
                -webkit-text-stroke: 0.75px var(--color-dark);
                text-shadow: 1px 1px 0px var(--color-dark), 2px 2px 0px var(--color-dark), 3px 3px 0px var(--color-dark),
                             4px 4px 0px var(--color-dark), 5px 5px 0px var(--color-dark), 6px 6px 0px var(--color-dark),
                             7px 7px 0px var(--color-dark), 8px 8px 0px var(--color-dark), 0px 0px 18px rgba(0,0,0,0.2);
                top: -3.5px;

                @include for-breakpoint-lg-up {
                    font-size: 3.5em;
                }

                @include for-breakpoint-xl-up {
                    font-size: 5.5em;

                    -webkit-text-stroke: 1px var(--color-dark);
                    text-shadow: 1px 1px 0px var(--color-dark), 2px 2px 0px var(--color-dark), 3px 3px 0px var(--color-dark),
                             4px 4px 0px var(--color-dark), 5px 5px 0px var(--color-dark), 6px 6px 0px var(--color-dark),
                             7px 7px 0px var(--color-dark), 8px 8px 0px var(--color-dark), 9px 9px 0px var(--color-dark),
                             10px 10px 0px var(--color-dark), 11px 11px 0px var(--color-dark), 0px 0px 18px rgba(0,0,0,0.2);
                    line-height: 1;
                    top: -5px;
                }

                @include for-breakpoint-xxl-up {
                    font-size: 8.75em;

                    -webkit-text-stroke: 1px var(--color-dark);
                    text-shadow: 1px 1px 0px var(--color-dark), 2px 2px 0px var(--color-dark), 3px 3px 0px var(--color-dark),
                             4px 4px 0px var(--color-dark), 5px 5px 0px var(--color-dark), 6px 6px 0px var(--color-dark),
                             7px 7px 0px var(--color-dark), 8px 8px 0px var(--color-dark), 9px 9px 0px var(--color-dark),
                             10px 10px 0px var(--color-dark), 11px 11px 0px var(--color-dark), 12px 12px 0px var(--color-dark),
                             13px 13px 0px var(--color-dark), 14px 14px 0px var(--color-dark), 15px 15px 0px var(--color-dark),
                             0px 0px 18px rgba(0,0,0,0.2);
                    line-height: 1;
                    // line-height: 0.92;
                    top: -10px;
                    // top: -12.5px;
                    // bottom: -7px;
                }
            }

        }
    }

    .links a {
        display: inline-block;
        margin: 17.5px;
        font-size: 4em;
        color: $variantD;
        -webkit-text-stroke: 0.5px var(--color-dark);

        @include for-breakpoint-lg {
            font-size: 2.25em;
            margin: 12.5px;
        }

        @include for-break-point {
            font-size: 1.75em;
            margin: 10px;
        }

        @include for-phone-only {
            margin: 7.5px;
            font-size: 1.5em;
        }

        &:hover {
            color: $variant;
        }

        &.biz-link {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 10px;
            margin-right: 10px;
        }

        img.biz-icon {
            width: 42px;
            height: auto;
            transition: all 250ms ease-in-out;
            filter: grayscale(100%) brightness(500%) ;

            &.icon-landscape {
                width: auto;
                height: 32px;
            }

            @include for-phone-only {
                width: 28px;

                &.icon-landscape {
                    width: auto;
                    height: 18px;
                }
            }
        }

        &:hover > img.biz-icon {
            opacity: 0.75;
        }

    }

    .link-divider {
        display: block;
        margin: -10px auto -7.5px auto;
        width: 80%;
        max-width: 320px;
        min-width: 80px;
        height: 1px;
        border-top: 1px solid $white-o8;
        opacity: 0.7;
    }
}

.mfp-bg.my-mfp-zoom-in.mfp-ready {
    transition: all 350ms ease-in-out;
}

.mfp-bg {
  opacity: 0;
  visibility: hidden;
  transition: all 350ms ease-in;

}

.mfp-content {
     transform: scale(0.5);
     @include transition(all 500ms ease-in-out);
}

.mfp-bg.mfp-ready {
  opacity: 0.8;
  visibility: visible;
}

.mfp-ready {

    .mfp-content  {
        transform: scale(1)!important;
    }
}

.mfp-close {
    color: $trim;
    font-size: 5em;
    opacity: 1;
    right: auto;
    left: 30px;
    top: 20px;
    color: var(--text);

    &:hover {
        color: $trimL;
        color: var(--color-text-o2);
    }
}


$subscribe: 550px;

#newsletter {
    position: fixed;
    z-index: 9999;
    left: auto;
    top: 0;
    bottom: 0;
    right: -$subscribe;
    right: -600px;
    height: 100vh;
    width: $subscribe;
    background: #ECE9E6;
    background: -webkit-linear-gradient(to right, #FFFFFF, #f1eeeb);
    background: linear-gradient(to right, #FFFFFF, #f1eeeb);
    transition: all 500ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -1px 0px 20px 0px rgba(0,0,0,0.2);
	color: $white;
    background-color: var(--color-bg);

    @supports (background-blend-mode: multiply) {
        background-image: url('../imgs/textures/subtle-grunge.png')!important;
        background-blend-mode: overlay!important;
        background-size: 28%!important;
        background-attachment: scroll!important;
    }

    @include for-breakpoint-sm {
        width: 100%;
    }

    &.thanks {

        #mc_embed_signup:after {
            font-size: 1.15em;
            font-weight: 700;
            font-family: $main-font;
            text-transform: uppercase;
            // color: $black-o4;
            color: var(--color-text-o4);
            content: "Thank you!";
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            margin: 15px auto;
            width: 100%;
            text-align: center;
            letter-spacing: 0.75px;
        }
    }

    &.overlay-active {
        right: 0;
    }

    .overlay-hue {
        opacity: 0.07;
        background-image: url('../imgs/banners/grain.png');
        z-index: 1;
        background-size: 20%;
    }

    .btn-wrapper {
        position: absolute;
        top: 15px;
        right: auto;
        left: 25px;
        z-index: 5;

        button {
            // color: $variant;
            // color: $secondary;
            font-size: 2.25em;
            font-weight: 400;
            -webkit-appearance: none;
            border: none;
            box-shadow: none;
            outline: none;
            padding: 5px;
            background: none;
            text-transform: lowercase!important;
            font-family: sans-serif!important;
            font-weight: 100!important;

            color: var(--text);

            &:active,
            &:focus {
                border: none;
                box-shadow: none;
                outline: none;
            }

            &:hover {
                // color: $variantD;
                // color: $secondaryD;

                color: var(--text);
            }
        }
    }

    #mc_embed_signup {
        clear:left;
        font-size: 0.9rem;
        font-family: $main-font;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        z-index: 3;
        position: relative;

        @include for-phone-only {
            width: 90%;
        }


        .asterisk {
            // color: $primary;

            color: var(--text);
        }

        label {
            font-size: 0.6rem;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1.5px;
            padding-top: 3.5px;
            // color: $black-o6;

            color: var(--color-text-o6);
        }

        input.button {
            text-transform: uppercase;
            letter-spacing: 2.5px;
            font-size: 1.15rem;
            height: 45px;
            margin-top: 10px;
            background: $variant;
            @include transition(all 250ms ease-in-out);
            font-family: $main-font;
            font-weight: 700;
            width: 96%;
            // border: 1px solid $blackL;
            // border: 1px solid $black;
            border: 1px solid var(--text);
            -webkit-box-shadow: 2px 2px 0px 2px $variantD;
            -moz-box-shadow: 2px 2px 0px 2px $variantD;
            box-shadow: 2px 2px 0px 2px $variantD;
            @include border-radius(3px);
            // -webkit-text-stroke: 0.5px $black-o1;
            background: $secondary;
            -webkit-box-shadow: 2px 2px 0px 2px $secondaryD;
            -moz-box-shadow: 2px 2px 0px 2px $secondaryD;
            box-shadow: 2px 2px 0px 2px $secondaryD;

            border: none;

            &:hover,
            &:active,
            &:focus {
                background: $secondaryD;
                // -webkit-box-shadow: 2px 2px 0px 2px $variantDD;
                // -moz-box-shadow: 2px 2px 0px 2px $variantDD;
                // box-shadow: 2px 2px 0px 2px $variantDD;

                -webkit-box-shadow: 2px 2px 0px 2px $secondaryDD;
                -moz-box-shadow: 2px 2px 0px 2px $secondaryDD;
                box-shadow: 2px 2px 0px 2px $secondaryDD;
            }
        }

        h2 {
            text-transform: uppercase;
            font-size: 1.5rem;
            // color: $secondary;
            letter-spacing: 2px;

            color: var(--text);

            @include for-phone-only {
                margin-bottom: 1.5em;
            }
        }

        .indicates-required {
            @include abs-pos(35.5px,0,auto,auto);
            top: 35px;
            // color: $black-o6!important;
            color: var(--color-text-o6)!important;

            @include for-phone-only {
                right: auto;
                left: 10px;
                top: 50px;
            }
        }

        .mc-field-group {
            padding-bottom: 2%!important;

            input.main-input {
                // color: $black;
                color: var(--text);
                font-size: 1rem;
                background: none;
                border: none;
                border-radius: 0;
                // border-bottom: 1px solid $black-o6;
                border-bottom: 1px solid var(--color-text-o6);
                transition: all 350ms ease-in;


                &:active,
                &:focus {
                    box-shadow: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    background: none;
                    outline: none;
                    // border-bottom: 1px solid $black-o2;
                    border-bottom: 1px solid var(--color-text-o2);
                }
            }
        }

        .mce_inline_error {
            font-size: 0.7rem!important;
            color: $primary!important;
            color: var(--text)!important;
            margin-bottom: -2.5px!important;
            padding-top: 7.5px!important;
            padding-bottom: 5px!important;
            background: none!important;
        }

        input.mce_inline_error {
            // border: 2px solid $primary;
            border: 2px solid var(--text);
            // color: $blackL!important;
            font-size: 1rem!important;
            color: var(--color-text-o1)!important;
        }
    }
}

.container-xl,
.container-md {
    transition: all 450ms ease-in-out;
}

// .container, .container-lg, .container-md, .container-sm, .container-xl {
//     max-width: 1280px;
// }

// .section.burnt {
//     filter: blur(4px);
//     transition: all 450ms ease-in-out;
// }

// .section {
//
//     -webkit-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
//    -moz-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
//      -o-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
//         transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
//
// -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
//    -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
//      -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
//         transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
// }



aside.fixed-sidebar {

}

.animate__animated.animate__delay-1p5s {
    -webkit-animation-delay: calc(1s * 1.5);
    animation-delay: calc(1s * 1.5);
    -webkit-animation-delay: calc(var(--animate-delay) * 1.5);
    animation-delay: calc(var(--animate-delay) * 1.5);
}

.animate__animated.animate__delay-3p5s {
    -webkit-animation-delay: calc(1s * 3.5);
    animation-delay: calc(1s * 3.5);
    -webkit-animation-delay: calc(var(--animate-delay) * 3.5);
    animation-delay: calc(var(--animate-delay) * 3.5);
}

.col-md-gone {
    @include for-breakpoint-md {
        display: none!important;
    }
}

body.game-page {
    background: $blackF;
    // background-color: var(--text);
    background-color: var(--color-dark);

    --text: var(--color-light);
    --mode: var(--light)!important;

    .section > .container-fluid {
        -webkit-mask-image: linear-gradient(to top, transparent 21px, black 21%);
      mask-image: linear-gradient(to top, transparent 21px, black 21%);
      z-index: 1;
    }

    .section {
        background-color: transparent!important;
    }

    .section.section-mod {
        height: 100%;
        height: 100vh!important;
    }

    .section.section-mod > .container-fluid {
      //   -webkit-mask-image: linear-gradient(to top, transparent 6px, black 7%);
      // mask-image: linear-gradient(to top, transparent 6px, black 7%);
      mask-image: none;
      -webkit-mask-image: none;
      z-index: 1;
      padding-bottom: 0;
    }


    #game-info {
        margin-left: auto;
        margin-right: auto;

        p.caption-xl {
            text-transform: uppercase;
            font-size: 1.5rem;
            color: $white;
            font-weight: 700;
            line-height: 130%;
            margin-left: 0!important;
            text-align: left;
            margin-right: auto!important;

            @include for-breakpoint-lg-up {
                max-width: 280px;
            }
        }

        img#arrow {
            position: relative;
            left: -35px;


        }

        @include for-breakpoint-lg-up {
            max-width: (768px * 1.2)!important;
        }

        @include for-breakpoint-lg {
            max-width: (768px * 0.95)!important;
        }

        @include for-breakpoint-md {
            width: (768px * 0.7)!important;
        }


    }


    #small-dialog {
        background: $primary;

        max-width: 480px;
        padding: 3rem;
        padding-top: 3.5rem;

        h2 {
            color: $trim;
            color: var(--text);
        }

        p {
            color: $white;

            a {
                color: $trim;
                color: $white;
                text-decoration: underline;
                text-underline-offset: 4px;
                text-decoration-thickness: 2px;

                &:hover {
                    color: $trimL;
                    color: $white-lighter;
                }
            }
        }

        .mfp-close {
            font-size: 2.5rem;
            font-weight: 700;
            top: -0.4rem;
            left: -0.4rem;
            color: $white;

        }
    }

    nav#social-nav a {
        color: var(--color-light)!important;
    }
}

body.live-page {
	background: $primary;
    // background-color: $blackF;
    background-color: var(--text);
        overflow: scroll;

    .section-mod {
        // background-color: $blackF;
        background-color: var(--text);
        overflow: scroll;
        position: relative;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-direction: column;
        // padding: 5em 3em;
    }

    .videoWrapper {
        position: relative;
      padding-bottom: 56.25%; /* 16:9 */

      padding-bottom: 0;
      padding-top: 56.25%;
      width: 100%;
      // width: 100%;

      height: 0;

      // width: 100%;
      // height: 100%;

      iframe {
          position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
      }
    }
}

#unityWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-bottom: 1em;


    iframe {
        width: (768px * 1.25)!important;
        height: (432px * 1.25)!important;

        @include for-breakpoint-lg {
            width: (768px * 0.95)!important;
            height: (432px * 0.95)!important;
        }

        @include for-breakpoint-md {
            width: (768px * 0.7)!important;
            height: (432px * 0.7)!important;
        }
    }


}

.section.section-mod {
    padding-top: 0;
    padding-bottom: 0;
    // overflow-x: hidden!important;

    #dummyblock {
        display: block;
        height: 20px;
        width: 100%;

        // height: 5px;
    }

    #game-info {
        margin-top: 3.5rem;
    }

    #partner-container {
        max-width: 100%;
        overflow-x: hidden!important;
    }
}

#unityContainer,
#unityFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0;

    iframe {
        width: 100%;
        height: 100%;
    }

    canvas {
      width: 100%;
      height: 100%;
      position: absolute;
    }
}

#unityFrame {

    // iframe {
    //     margin-top: 65px;
    //     display: block;
    // }
}
