#main-menu {
    background: none;
    box-shadow: none;
    z-index: 100;
    transition: all 300ms ease-in-out;

    @include for-breakpoint-md {
        position: absolute;
        padding: 0!important;


        .nav-container {
            padding: 0!important;
            text-align: right;
        }

        .container.nav-container {
            max-width: 100%!important;
        }
    }

    &.overlay-active {
        opacity: 0;
        visibility: hidden;
        top: -100px;
    }

    #collapsibleNavbar {
        @include for-breakpoint-md {
            transform:translateY(-50px);
        }
    }

    .navbar-brand {
        display: none;
        width: 65%;
        float: left;
        position: relative;
        padding-left: 15px;

        img.busty-logo {
            width: 75%;
            max-width: 420px;
            min-width: 225px;
            height: auto;
            margin-left: 0;
            margin-right: auto;
            display: block;
        }

        @include for-breakpoint-md {
            display: inline-block;
        }
    }

    .navbar-toggler {
        @include for-breakpoint-md {
            z-index: 5000!important;
            display: inline-block;
            align-self: flex-end;
            padding: 15px;
            border: none;
            color: $white;
            opacity: 1;
            margin-right: 5px;

            color: $primary;
        }
    }

    #mainNavbar {
        justify-content: space-around;
        align-items: center;


        @include for-breakpoint-md-up {
            max-width: 700px!important;
        }

        @include for-breakpoint-md {
            background: $trim;
            height: 100vh;
            padding-top: 75px;
            justify-content: flex-start;
        }

        li.nav-item {

            .nav-link {
                font-size: 1.05em;
                letter-spacing: 2px;
            }

            &.active {
                background: none!important;

                a.nav-link {
                    background: none!important;
                    font-weight: 700;
                    pointer-events: none;
                }
            }

            @include for-breakpoint-md {
                padding: 15px 0;
            }
        }

        a.nav-link {
            // color: $black;
            font-size: 1.05em;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: 400;

            color: var(--text);

            &:hover {
                // color: $blackL;
                color: var(--color-text-o1);
            }

			@include for-break-point {
				color: $variant;

				&:hover {
	                color: $variantL;
	            }
			}
        }

        li.nav-item.active a.nav-link:hover {
            // color: $black;
            color: var(--text);

			// @include for-break-point {
			// 	color: $variant;
			// }
        }
    }
}

#main-menu.navbar-gamepage {

    @include for-breakpoint-md {

        #collapsibleNavbar {
            -webkit-transform: translateY(-50px);
            transform: translateY(-70px);
        }

        .navbar-brand img.busty-logo {
            filter: invert(80%);
        }
    }

    #mainNavbar {
        justify-content: space-between;

        @include for-breakpoint-md-up {
            a.nav-link {
                color: $white;
            }
        }


        @include for-breakpoint-md {
            background: $trim;
            height: 100vh;
            padding-top: 75px;
            justify-content: flex-start;
        }
    }
}

body.game-page.play-page #main-menu.navbar-gamepage {

    @include for-breakpoint-md-up {
        position: relative;
    }

}

nav#social-nav {
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    margin: 0 auto;
    z-index: 100;
    height: 45px;
    transition: all 300ms ease-in-out;

    &.widget-nav {
        width: 66%;
    }

    @include for-breakpoint-md {
        position: absolute;
        width: 70%;
    }

    @include for-phone-only {
        height: 55px;
    }

    &.overlay-active {
        opacity: 0;
        visibility: hidden;
        bottom: -100px;
    }

    a {
        font-size: 1.5em;
        color: $trim!important;
        color: var(--color-text-o1)!important;
        // -webkit-text-stroke: 0.25px $black-o1;
        -webkit-text-stroke: 0.25px var(--color-text-o2);
        // text-shadow: 0px 0px 6px $black-o6;
		text-shadow: 0px 0px 6px rgba(255,255,255,0.2);

        &:hover {
            color: $trimL!important;
        }

        &.appleMusicWidget {
            transform: scale(0.7) translateX(-45px);
        }

    }

    iframe.spotifyWidget {
        transform: scale(0.7) translateX(66px) translateY(-5px);
    }

    &.social-icons {
        bottom: -50px;
        transition: all 500ms ease-in-out;

        a {
            font-size: 1.65em;
        }

        &.pop-up {
            bottom: 0;
        }
    }

    &.widget-nav-alt {

        width: 35%;

        .badges {
            position: fixed;
            left: 5px;
            bottom: -15px;
            transform:scale(0.7);

            iframe,a {
                display: block;
                margin-left: 0;
                margin-right: auto;
                margin-bottom: 15px;
            }

            a {
                margin-bottom: 10px;
            }
        }


    }

    &.social-nav-lg {


        a {
            font-size: 1.68em;
        }

        @include for-phone-only {
            width: 82%;

            a {
                font-size: 1.52em;
            }
        }
    }

    &.social-nav-mod {
        position: relative;
    }
}
