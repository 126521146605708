

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
     -moz-box-sizing: $box-model; // Firefox <= 19
          box-sizing: $box-model;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin filter($filter-type,$filter-amount) {
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}

@mixin abs-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  position: absolute;
}

@mixin fix-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  position: fixed;
}

@mixin no-select {
    -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
       -ms-user-select: none; /* Internet Explorer/Edge */
           user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome and Opera */
}

/* IMAGE MIXINS */

/* Fixed Background Image */
@mixin img-fixed {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin img-crop {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
}

/* Alternative to img-crop (could also be applied to centering objects)*/

@mixin img-wrap {
  position: absolute;
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%;
  margin: auto;
}

@mixin img-crop-scale-hover {
    -webkit-transform: translate(-50%,-50%) scale(1.07);
      -ms-transform: translate(-50%,-50%) scale(1.07);
      transform: translate(-50%,-50%) scale(1.07);
}

/* Image Hover Bug Fix */

@mixin img-bug-fix {
    -moz-transform: translateZ(0.0);
     -o-transform: translateZ(0.0);
    -ms-transform: translateZ(0.0);
-webkit-transform: translateZ(0.0);
       transform: translateZ(0.0);
}


/* Custom Mixins */

@mixin align-middle {
    display: table-cell;
    vertical-align: middle;
}

@mixin align-top {
    display: table-cell;
    vertical-align: top;
}

@mixin align-bottom {
    display: table-cell;
    vertical-align: bottom;
}

/* flex stuff */

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}

@mixin align-content($align) {
  -webkit-align-content: $align;
     -moz-align-content: $align;
      -ms-align-content: $align;
          align-content: $align;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
     -moz-align-items: $align;
      -ms-align-items: $align;
       -ms-flex-align: $align;
          align-items: $align;
}

@mixin align-self($align) {
  -webkit-align-self: $align;
     -moz-align-self: $align;
      -ms-align-self: $align;
          align-self: $align;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
     -moz-box-ordinal-group: $val;
  	     -ms-flex-order: $val;
  	      -webkit-order: $val;
  		      order: $val;
}

@mixin flex-wrap($value: nowrap) {
  // No Webkit/FF Box fallback.
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}


/* This applies to the :after */

@mixin mo-border-1 {
        content:"";
        position:absolute;
        width:100%;
        height:1px;
        bottom:-1px;
        left:0;
        background-color:#F2F2F2;
        visibility:hidden;
        -moz-transform: scaleX(0);
 	     -o-transform: scaleX(0);
 	    -ms-transform: scaleX(0);
 	-webkit-transform: scaleX(0);
           transform: scaleX(0);
}

/* This applies to the :hover:after */

@mixin mo-border-2 {
        visibility:visible;
        -moz-transform: scaleX(1);
 	     -o-transform: scaleX(1);
 	    -ms-transform: scaleX(1);
 	-webkit-transform: scaleX(1);
           transform: scaleX(1);
}


/* Header border applied to &::after */

@mixin header-line {
        content: "";
        display: block;
        margin: 25px auto;
        margin-top: 20px;
        margin-left: 0;
        height: 9px;
        width: 150px;
        background: $secondary;
}

@mixin title-caption {
    font-family: $title-font;
    text-transform: uppercase;
    font-size: 0.35rem;
    letter-spacing: 5px;
    margin-left: 5px;
    color: $black-haze;
    font-weight: 700;
}

@mixin fadeout-gradient {
    background: rgba(255,255,255,0);
background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.98) 82%, rgba(255,255,255,1) 88%, rgba(255,255,255,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(82%, rgba(255,255,255,0.98)), color-stop(88%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.98) 82%, rgba(255,255,255,1) 88%, rgba(255,255,255,1) 100%);
background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.98) 82%, rgba(255,255,255,1) 88%, rgba(255,255,255,1) 100%);
background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.98) 82%, rgba(255,255,255,1) 88%, rgba(255,255,255,1) 100%);
background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.98) 82%, rgba(255,255,255,1) 88%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
}



@mixin for-phone-only {
  @media (max-width: 575px) { @content; }
}

@mixin for-phone-up {
  @media (min-width: 576px) { @content; }
}

@mixin for-tablet-phone-down {
  @media (max-width: 991px) { @content; }
}

@mixin for-breakpoint-lg {
  @media (max-width: 991px) { @content; }
}

@mixin for-breakpoint-lg-up {
  @media (min-width: 992px) { @content; }
}

@mixin for-tablet-phone-up {
  @media (min-width: 992px) { @content; }
}

@mixin for-tablet-down {
  @media (max-width: 1100px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-break-point {
  @media (max-width: 767px) { @content; }
}

@mixin for-break-point-up {
  @media (min-width: 768px) { @content; }
}

@mixin for-breakpoint {
  @media (max-width: 767px) { @content; }
}

@mixin for-breakpoint-up {
  @media (min-width: 768px) { @content; }
}

@mixin for-breakpoint-md {
  @media (max-width: 767px) { @content; }
}

@mixin for-breakpoint-md-up {
  @media (min-width: 768px) { @content; }
}

@mixin for-breakpoint-lg-up {
  @media (min-width: 992px) { @content; }
}

@mixin for-breakpoint-sm {
    @media (max-width: 574px) { @content; }
}

@mixin for-breakpoint-sm-up {
    @media (min-width: 575px) { @content; }
}

@mixin for-breakpoint-xl {
    @media (max-width: 1199px) { @content; }
}

@mixin for-breakpoint-xl-up {
    @media (min-width: 1200px) { @content; }
}

@mixin for-breakpoint-xxl {
    @media (max-width: 1279px) { @content; }
}

@mixin for-breakpoint-xxl-up {
    @media (min-width: 1280px) { @content; }
}


@mixin for-tablet-landscape-down {
  @media (max-width: 899px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin for-sm-desktop-down {
  @media (max-width: 1199px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
